var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomBottomSheet',{attrs:{"refName":'SharedMediaFolderInfo',"size":"xl","headerText":_vm.$t('SharedMediaFolders.data'),"headerIcon":_vm.sharedMediaFolder.icon}},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.sharedMediaFolder.fullCode,"title":_vm.$t('SharedMediaFolders.code'),"imgName":'code.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.sharedMediaFolder.sharedMediaFolderNameAr,"title":_vm.$t('SharedMediaFolders.titleAr'),"imgName":'sharedMediaFolders.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.sharedMediaFolder.sharedMediaFolderNameEn,"title":_vm.$t('SharedMediaFolders.titleEn'),"imgName":'sharedMediaFolders.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.sharedMediaFolder.sharedMediaFolderNameUnd,"title":_vm.$t('SharedMediaFolders.titleUnd'),"imgName":'sharedMediaFolders.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.sharedMediaFolder.sharedMediaFolderDescriptionAr,"title":_vm.$t('SharedMediaFolders.descriptionAr'),"imgName":'description.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.sharedMediaFolder.sharedMediaFolderDescriptionEn,"title":_vm.$t('SharedMediaFolders.descriptionEn'),"imgName":'description.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.sharedMediaFolder.sharedMediaFolderDescriptionUnd,"title":_vm.$t('SharedMediaFolders.descriptionUnd'),"imgName":'description.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.sharedMediaFolder.sharedMediaFolderNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'}}),(
        _vm.sharedMediaFolder.sharedMediaFolderTypeToken ==
        _vm.SHARED_MEDIA_TYPE.Genral
      )?_c('DataLabelGroup',{attrs:{"className":'col-md-12',"value":'-',"title":_vm.$t('SharedMediaFolders.sharedWith'),"imgName":'sharedMediaFolders.svg'}}):_vm._e(),(
        _vm.sharedMediaFolder.sharedMediaFolderTypeToken ==
        _vm.SHARED_MEDIA_TYPE.EducationalCategory
      )?_c('DataLabelGroup',{attrs:{"className":'col-md-12',"value":_vm.sharedMediaFolder.educationalCategoryInfoData
          .educationalCategoryNameCurrent,"title":_vm.$t('SharedMediaFolders.sharedWith'),"imgName":'EducationalCategories.svg'}}):_vm._e(),(
        _vm.sharedMediaFolder.sharedMediaFolderTypeToken ==
        _vm.SHARED_MEDIA_TYPE.EducationalGroup
      )?_c('DataLabelGroup',{attrs:{"className":'col-md-12',"value":_vm.sharedMediaFolder.educationalGroupInfoData.educationalGroupNameCurrent,"title":_vm.$t('SharedMediaFolders.sharedWith'),"imgName":'EducationalGroups.svg'}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }