var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row custom-cards"},_vm._l((_vm.sharedMediaFoldersData),function(sharedMediaFolder,index){return _c('CustomCard',{key:index,attrs:{"className":'col-sm-12 col-md-6 col-lg-4',"title":sharedMediaFolder.sharedMediaFolderNameCurrent,"description":sharedMediaFolder.fullCode,"imagePath":sharedMediaFolder.sharedMediaFolderImagePath,"defaultImg":_vm.defaultImg,"index":++index + _vm.filterData.currentIndex}},[(_vm.checkPrivilege(_vm.hasSharedMedia()))?_c('li',[_c('router-link',{attrs:{"to":{
          name: 'SharedMedias',
          params: {
            sharedMediaFolderToken: sharedMediaFolder.sharedMediaFolderToken,
          },
        },"title":_vm.$t('SharedMedias.modelName')}},[_c('img',{attrs:{"src":require("@/assets/images/media.svg")}})])],1):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('info')},on:{"click":function($event){_vm.setSharedMediaFolderData(sharedMediaFolder);
          _vm.openBottomSheet('SharedMediaFolderInfo');}}},[_c('img',{attrs:{"src":require("@/assets/images/info.svg")}})])]),_c('li',[_c('button',{attrs:{"title":_vm.$t('general.qrCode')},on:{"click":function($event){_vm.setSharedMediaFolderData(sharedMediaFolder);
          _vm.openBottomSheet('SharedMediaFolderQRCode');}}},[_c('img',{attrs:{"src":require("@/assets/images/qr-code.svg")}})])]),(_vm.checkPrivilege(_vm.hasSharedMediaFolderEdit()))?_c('li',[_c('button',{attrs:{"title":_vm.$t('edit')},on:{"click":function($event){_vm.setSharedMediaFolderData(sharedMediaFolder);
          _vm.openBottomSheet('SharedMediaFolderUpdate');}}},[_c('img',{attrs:{"src":require("@/assets/images/pencil.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasSharedMediaFolderFinaleDelete()))?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.SharedMediaFolderDelete",modifiers:{"SharedMediaFolderDelete":true}}],attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setSharedMediaFolderData(sharedMediaFolder)}}},[_c('img',{attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasSharedMediaFolderChangeActivationType()))?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.SharedMediaFolderChangeActivationType",modifiers:{"SharedMediaFolderChangeActivationType":true}}],attrs:{"title":_vm.$t('changeActivationType')},on:{"click":function($event){return _vm.setSharedMediaFolderData(sharedMediaFolder)}}},[_c('img',{attrs:{"src":require("@/assets/images/changeActivationType.svg")}})])]):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('actionsData.modelName')},on:{"click":function($event){_vm.setSharedMediaFolderData(sharedMediaFolder);
          _vm.openBottomSheet('ActionsData');}}},[_c('img',{attrs:{"src":require("@/assets/images/actions-data.svg")}})])])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }