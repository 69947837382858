var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.sharedMediaFolder.sharedMediaFolderImagePath,
            _vm.sharedMediaFolder.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.sharedMediaFolder.sharedMediaFolderImageIsDefault &&
          _vm.checkPrivilege(_vm.hasSharedMediaFolderDeleteImage())},on:{"changeValue":function($event){_vm.sharedMediaFolder.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomSelectBox',{attrs:{"className":'col-md-12',"id":(_vm.id + "-sharedMediaFolderTypeToken"),"value":_vm.sharedMediaFolder.sharedMediaFolderTypeToken,"options":_vm.sharedMediaFolderTypeTokenOptions,"title":_vm.$t('general.type'),"imgName":'type.svg'},on:{"changeValue":function($event){_vm.sharedMediaFolder.sharedMediaFolderTypeToken = $event;
            _vm.sharedMediaFolder.mainModuleToken = '';}}}),(
            _vm.sharedMediaFolder.sharedMediaFolderTypeToken ==
            _vm.SHARED_MEDIA_TYPE.EducationalCategory
          )?_c('CustomSelectBox',{attrs:{"className":'col-md-12',"id":(_vm.id + "-educationalCategoryToken"),"value":_vm.sharedMediaFolder.mainModuleToken,"options":_vm.educationalCategoryTokenOptions,"title":_vm.$t('EducationalCategories.select'),"imgName":'EducationalCategories.svg'},on:{"changeValue":function($event){_vm.sharedMediaFolder.mainModuleToken = $event}}}):_vm._e(),(
            _vm.sharedMediaFolder.sharedMediaFolderTypeToken ==
            _vm.SHARED_MEDIA_TYPE.EducationalGroup
          )?_c('CustomSelectBox',{attrs:{"className":'col-md-12',"id":(_vm.id + "-educationalGroupToken"),"value":_vm.sharedMediaFolder.mainModuleToken,"options":_vm.educationalGroupTokenOptions,"title":_vm.$t('EducationalGroups.select'),"imgName":'EducationalGroups.svg'},on:{"changeValue":function($event){_vm.sharedMediaFolder.mainModuleToken = $event}}}):_vm._e(),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.sharedMediaFolder.fullCode,"title":_vm.$t('SharedMediaFolders.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.sharedMediaFolder.fullCode = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-sharedMediaFolderNameAr"),"errors":_vm.errors_sharedMediaFolderNameAr,"value":_vm.sharedMediaFolder.sharedMediaFolderNameAr,"title":_vm.$t('SharedMediaFolders.titleAr'),"imgName":'sharedMediaFolders.svg'},on:{"changeValue":function($event){_vm.sharedMediaFolder.sharedMediaFolderNameAr = $event;
            _vm.$v.sharedMediaFolder.sharedMediaFolderNameAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-sharedMediaFolderNameEn"),"errors":_vm.errors_sharedMediaFolderNameEn,"value":_vm.sharedMediaFolder.sharedMediaFolderNameEn,"title":_vm.$t('SharedMediaFolders.titleEn'),"imgName":'sharedMediaFolders.svg'},on:{"changeValue":function($event){_vm.sharedMediaFolder.sharedMediaFolderNameEn = $event;
            _vm.$v.sharedMediaFolder.sharedMediaFolderNameEn.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-sharedMediaFolderNameUnd"),"value":_vm.sharedMediaFolder.sharedMediaFolderNameUnd,"title":_vm.$t('SharedMediaFolders.titleUnd'),"imgName":'sharedMediaFolders.svg'},on:{"changeValue":function($event){_vm.sharedMediaFolder.sharedMediaFolderNameUnd = $event}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-sharedMediaFolderDescriptionAr"),"errors":_vm.errors_sharedMediaFolderDescriptionAr,"value":_vm.sharedMediaFolder.sharedMediaFolderDescriptionAr,"title":_vm.$t('SharedMediaFolders.descriptionAr'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.sharedMediaFolder.sharedMediaFolderDescriptionAr = $event;
            _vm.$v.sharedMediaFolder.sharedMediaFolderDescriptionAr.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-sharedMediaFolderDescriptionEn"),"errors":_vm.errors_sharedMediaFolderDescriptionEn,"value":_vm.sharedMediaFolder.sharedMediaFolderDescriptionEn,"title":_vm.$t('SharedMediaFolders.descriptionEn'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.sharedMediaFolder.sharedMediaFolderDescriptionEn = $event;
            _vm.$v.sharedMediaFolder.sharedMediaFolderDescriptionEn.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-sharedMediaFolderDescriptionUnd"),"value":_vm.sharedMediaFolder.sharedMediaFolderDescriptionUnd,"title":_vm.$t('SharedMediaFolders.descriptionUnd'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.sharedMediaFolder.sharedMediaFolderDescriptionUnd = $event}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-sharedMediaFolderNotes"),"value":_vm.sharedMediaFolder.sharedMediaFolderNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.sharedMediaFolder.sharedMediaFolderNotes = $event}}})],1)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }